import React from 'react'

import Container from '../components/container'
import WorkFeature from '../components/work-feature'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import styles from './project-page.module.css'

import GreenIcon from '../components/assets/icons/GreenCircle.svg'

const ClimatePage = () => {
  return (
    <Layout>
      <SEO
        title="Climate Commitment"
        description="A promise for today's world, and hope for the future."
      />

      <WorkFeature
        title="Climate Commitment"
        subtitle="A promise for today's world, and hope for the future."
        backgroundColor="#000000"
        color="#ffffff"
        featureVisual={"https://player.vimeo.com/video/714255026?quality=2k&background=1&autoplay=1&loop=1&muted=1&autopause=0&byline=0&title=0&playsinline=1&controls=0&dnt=1"}
      ></WorkFeature>

      <Container>
        <div className={styles.wrap}>
          <div className={styles.textBox}>
            <div className={styles.aboutBlock + ' ' + styles.green}>
              <div>
                <img src={GreenIcon} className={styles.aboutBlockImage} alt="plant icon"></img>
                <h3>Carbon Negative since 2021</h3>
                <p>
                  Personal life, projects, and businesses are carbon offset by 110% yearly.{' '}
                  <br></br>
                  Sustainable resources used whenever possible.
                </p>
              </div>
            </div>

            <p>
              <h2 className={styles.h2}>Investing in our future.</h2>
              <br />
              <br />
              As of 2021, I am offsetting the carbon emissions from my personal life, and all of my
              work by 110%.
              <br />
              <br />
              <br />
              <div className={styles.hr}></div>
              <br />
              <br />
              The evidence behind a severe change in climate over the next century is irrefutable.
              Without countermeasures our planet will undergo drastic shifts in ocean water levels,
              natural disasters, weather, and temperature.
              <br />
              <br />
              In short, more CO2 causes global temperatures to increase, which in turn causes
              negative effects. 
              This doesn't mean that the entire world is doomed, but it does mean that millions of people (especially those in vulnerable positions) will die because of the damage we've done to the environment.
              <br />
              <br />I tend to be optimistic about humanity’s ability to manage this problem, and as
              a matter of fact, I’m personally starting an organization, called <a href='https://gosolve.org' target="_blank" rel="noreferrer"><b>goSolve</b></a>,
              specifically designed to make united problem-solving more accessible to people
              worldwide.
              <br />
              <br />
              While some change is inevitable (as Earth is a constantly evolving system), it does
              not mean we can afford to disregard the very real threat this poses to ourselves and
              our descendants.
              <br />
              <br />
              As any responsible person would note, “We should be good stewards of the things we’ve
              been given.”
              <br />
              <br />
            </p>
          </div>
        </div>
      </Container>
      <div className={styles.vidContainBlack}>
        <iframe
          className={styles.fullVid}
          src="https://player.vimeo.com/video/526247822?quality=1080p&background=1&autoplay=1&loop=1&muted=1&autopause=0&byline=0&title=0&playsinline=1&controls=0&dnt=1"
          frameBorder="0"
          title="NASA Climate over time video"
        ></iframe>
      </div>

      <Container>
        <div className={styles.wrap}>
          <div className={styles.textBox}>
            <p>
              <i>
                NASA -{' '}
                <a href="https://climate.nasa.gov/" target="_blank" rel="noopener noreferrer">
                  Source
                </a>
              </i>
              <br />
              <br />
              <div className={styles.hr}></div>
              <br />
              <br />
              This statement is merely representative of actions I am performing behind the scenes.
              In practice, I’ll be recording resources used (electricity, water, gas, etc.) and
              paying to offset the carbon emissions. This extends to the emissions from physical
              purchases, servers, websites, and services I use.
              <br />
              <br />
              I will also be using sustainable and carbon neutral resources whenever possible.
              <br />
              <br />
              <b>I want to be clear</b>, Carbon Offsets are not the solution to this problem. I will
              still be releasing close to the same amount of carbon as before. Offsets simply
              compensate by removing an equivalent amount of emissions via alternate methods (for
              example planting trees, or capturing carbon as it escapes from abandoned mines).
              <br />
              <br />
              <i>
                Alone I cannot solve the problem, but at the very least I should be invested in
                cleaning up my own mess.{' '}
              </i>
              <br />
              <br />
              I have been overjoyed to see our world taking steps towards a carbon-neutral future,
              and I'm eager to be a part of that moving forward.
              <br />
              <br />
              We can do this together.
              <br />
              <br />
              - Jarren
              <br />
              <br />
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ClimatePage
